import Layout from '../layouts/layout';

const ProductsPage = () => {
  return (
    <Layout title="Productos">
      <section>Pronto!</section>
    </Layout>
  );
};

export default ProductsPage;
